import { Row, Col } from "antd"
import { Link } from 'react-router-dom';
import Container from "../../common/Container"

import {
  FooterSection,
  Extra,
  Para,
  Chat,
  FooterContainer,
  Language,
} from "./styles"


const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{"A Propos"}</Language>
            
              <Para>
                {`Contract Analyzer est un produit de la société NORMA.`}
                <a href="https://www.norma.dev/" target="_blank">{` En Savoir Plus`}</a>
              </Para>
              
            </Col>
          
            <Col lg={6} md={6} sm={12} xs={12}>
            <a style={{ textDecoration:"underline",fontWeight: "bold" }} href='/mentions_legales'>Mentions légales</a>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
      <Container border={true}>
          <Row
            justify="space-around"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            
            <FooterContainer>
              Copyright © 2021 NORMA - Template "Landy" by Falconix
            </FooterContainer>
          </Row>
        </Container>
    
      </Extra> 
    </>
  );
};

export default Footer;
