import React from 'react'
import Container from "../../common/Container"
const index = () => {
    return (
        <>
        <Container>    
<div style={{display:"flex",flexDirection:"column",padding:"50px",marginTop:"100px",marginBottom:"100px",borderStyle:"inset"}}>
<p style={{fontWeight:"bold",fontSize:"15px"}}>Responsable de la collecte des données personnelles</p>
<p style={{fontSize:"15px"}}>Pour les données personnelles collectées dans le cadre de la création du compte personnel de l’utilisateur et de sa navigation sur le site, le responsable du traitement des données personnelles : est la SAS Norma, <a style={{textDecoration:"underline"}} href="https://www.google.com/maps/place/96+Rue+Jeanne+d'Arc,+76000+Rouen,+France/@49.4407355,1.0085863,11z/data=!4m5!3m4!1s0x47e0ddd7490cd281:0x4bc0854524117835!8m2!3d49.4462026!4d1.0932292" >96 rue Jeanne d'Arc 76000 Rouen FRANCE </a>, RCS 887 579 985 00011, <a style={{textDecoration:"underline"}} href="tel:+33 6 98 11 13 64" >+33 6 98 11 13 64 </a> , <a style={{textDecoration:"underline"}} href="mailto:contact@norma.dev" >contact@norma.dev </a></p>
<p style={{fontSize:"15px"}}>En tant que responsable du traitement des données qu’il collecte, la SAS Norma s’engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient notamment d’établir les finalités de ses traitements de données, de fournir aux utilisateurs, à partir de la collecte de leurs consentements, une information complète sur le traitement de leurs données personnelles et de maintenir un registre des traitements.</p>
<p style={{fontSize:"15px"}}>Chaque fois que la SAS Norma traite des données personnelles depuis le site internet <a style={{textDecoration:"underline"}} href="www.moncdd.com" >www.moncdd.com </a> ,  toutes les mesures raisonnables sont prises pour s’assurer de l’exactitude et de la pertinence des données  personnelles  au regard des finalités pour lesquelles <a style={{textDecoration:"underline"}} href="www.moncdd.com" >www.moncdd.com </a>  les traite.
 </p>
<p style={{fontWeight:"bold",fontSize:"15px"}}>Finalité et nature des données collectées</p>
<p style={{fontSize:"15px"}}> La SAS Norma est susceptible de traiter tout ou partie des données   :
 </p>
 <ul> 
 <li style={{fontSize:"15px"}}>pour permettre la navigation sur le site et la gestion et la traçabilité des prestations et services commandés par l’utilisateur : données de connexion et d’utilisation du site, etc.</li>
 <li style={{fontSize:"15px"}}>pour prévenir et lutter contre la fraude informatique (spamming, hacking…) : matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé)</li>
 <li style={{fontSize:"15px"}}>pour améliorer la navigation sur le site : données de connexion et d’utilisation</li>
<li style={{fontSize:"15px"}}>pour mener des enquêtes de satisfaction facultatives sur <a style={{textDecoration:"underline"}} href="www.moncdd.com" >www.moncdd.com </a>  : adresse courriel</li>
<li style={{fontSize:"15px"}}>pour répondre aux questions posées via les formulaires de contact : nom, prénom et courriel du demandeur</li>
<li style={{fontSize:"15px"}}>pour bénéficier d’un compte personnel pour suivre son dossier en ligne : nom, prénom, adresse de résidence, n° de téléphone et courriel</li>
</ul>

<p style={{fontWeight:"bold",fontSize:"15px"}}>Durée de conservation</p>
<p style={{fontSize:"15px"}}>Les données sont conservées sauf durée contraire précisée dans les règles générales d’utilisation du site :</p>
<ul>
<li style={{fontSize:"15px"}}>dans la limite du temps nécessaire à l’instruction de la demande</li>
<li style={{fontSize:"15px"}}>jusqu’à ce que l’utilisateur annule son consentement, fasse valoir ses droits ou supprime son compte.</li>
 </ul>
<p style={{fontWeight:"bold",fontSize:"15px"}}>Droit d’accès, de rectification et d’opposition</p>
<p style={{fontSize:"15px"}}>Conformément à la réglementation européenne et nationale en vigueur, les utilisateurs de <a style={{textDecoration:"underline"}} href="www.moncdd.com" >www.moncdd.com </a>  disposent des droits suivants :</p>
<ul>
<li style={{fontSize:"15px"}}>droit d’accès et de rectification de mise à jour, de complétude des données des utilisateurs</li>
<li style={{fontSize:"15px"}}>droit de verrouillage ou d’effacement des données des utilisateurs à caractère personnel, lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l’utilisation, la communication ou la conservation est interdite</li>
<li style={{fontSize:"15px"}}>droit de retirer à tout moment un consentement</li>
<li style={{fontSize:"15px"}}>droit à la limitation du traitement des données des utilisateurs</li>
<li style={{fontSize:"15px"}}>droit d’opposition au traitement des données des utilisateurs</li>
<li style={{fontSize:"15px"}}>droit de définir le sort des données des utilisateurs et de choisir à qui <a style={{textDecoration:"underline"}} href="www.moncdd.com" >www.moncdd.com </a>  devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
</ul>
<p style={{fontSize:"15px"}}>Si l’utilisateur souhaite savoir comment <a style={{textDecoration:"underline"}} href="www.moncdd.com" >www.moncdd.com </a>  utilise ses données personnelles, demander à les rectifier ou s’oppose à leur traitement, l’utilisateur peut contacter le délégué à la protection des données via :</p>
<ul>
<li style={{fontSize:"15px"}}>par courriel à  <a style={{textDecoration:"underline"}} href="mailto:contact@norma.dev" >contact@norma.dev </a></li>
<li style={{fontSize:"15px"}}>par écrit à l’adresse suivante : SAS Norma Délégué à la protection des données 96 rue Jeanne d'Arc 76000 Rouen.  </li>
</ul>
<p style={{fontSize:"15px"}}>Dans ce cas, l’utilisateur doit indiquer les données personnelles qu’il souhaiterait que la SAS Norma corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport).</p>
<p style={{fontSize:"15px"}}>Les demandes de suppression de données personnelles seront soumises aux obligations qui sont imposées à la SAS Norma par la loi, notamment en matière de conservation ou d’archivage des documents.</p>
<p style={{fontSize:"15px"}}>Enfin, les utilisateurs de <a style={{textDecoration:"underline"}} href="www.moncdd.com" >www.moncdd.com </a>  peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (<a style={{textDecoration:"underline"}} href="www.cnil.fr/fr/plaintes" >www.cnil.fr/fr/plaintes </a> ).</p>
<p style={{fontWeight:"bold",fontSize:"15px"}}>Non-communication des données personnelles</p>
<p style={{fontSize:"15px"}}>La SAS Norma s’interdit de traiter, héberger ou transférer les Informations collectées vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat ».</p>
<p style={{fontSize:"15px"}}>La SAS Norma s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées.</p>
<p style={{fontSize:"15px"}}>Cependant, si un incident impactant l’intégrité ou la confidentialité des informations d’un utilisateur est portée à sa connaissance, la SAS Norma devra dans les meilleurs délais l’informer et lui communiquer les mesures de corrections prises.</p>
<p style={{fontSize:"15px"}}>Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des utilisateurs de <a style={{textDecoration:"underline"}} href="www.moncdd.com" >www.moncdd.com </a>  sont principalement les agents de la SAS Norma.</p>
</div>
</Container>
        </>
    )
}

export default index
